import React, { Component } from 'react';
import countapi from 'countapi-js';

class VisitLibri extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    countapi.visits('libri').then((result) => {
      console.log(result)
      this.setState({ visiteLibri: result.value });
    });
  }

  render() {

    return (
      <span style={{fontSize: '18px', marginBottom: '-20.5px'}} title="Numero delle visite sulla pagina 'Libri'">(👁 {this.state.visiteLibri})</span>
    );

  }
}

export default VisitLibri;